import React from "react";
import Slider from "./Slider";

function PrivacyPolicy() {
  return (
    <>
      <div className="main_PrivacyPolicy_div">
        <div className="inner_PrivacyPolicy_banner_div">
          <div className="inner_PrivacyPolicy_banner_text_div">
            Privacy Policy
          </div>
        </div>

        <div className="inner_PrivacyPolicy_body_text_div">
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Thank you for your interest in data protection. We are delighted to
            offer you a clear and concise overview of our data processing
            practices and privacy compliance measures concerning VIBE’s mobile
            application. This Privacy Policy describes the policies and
            procedures regarding the collection, use, and disclosure of your
            information when you use E-commerce mobile application, Vibe (“the
            Service”),
          </p>

          {/* <div className='main_PrivacyPolicy_slider_img'>
                        <Slider />
                    </div> */}
          <div className="inner_PrivacyPolicy_body_heading_div">
            Interpretation and Definitions
          </div>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            Interpretation
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            The capitalized terms used in this Privacy Policy have specific
            meanings outlined below. These definitions apply regardless of
            whether the terms appear in singular or plural form.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            Definitions
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            For the purposes of this Privacy Policy:
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Account</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            means a unique account created for you to access our Service or
            parts of our Service.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            Application
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            means the software program provided by Pakistan State Oil Company
            Limited, namely Vibe, downloaded by you on any electronic device.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Cookies</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            are small files placed on your computer, mobile device, or any other
            device by a website, containing details of your browsing history on
            that website among its many uses.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Country</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            refers to Pakistan.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Device</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            means any device that can access the Service such as a
            computer/laptop, cell phone, or digital tablet.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            is any information that relates to an identified or identifiable
            individual.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Service</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            refers to VIBE, the E-commerce mobile application.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            Service Provider
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            means any natural or legal person who processes data on behalf of
            Vibe. It refers to third-party companies or individuals employed by
            Vibe to facilitate the Service, provide the Service on behalf of
            Vibe, perform services related to the Service, or assist Vibe in
            analyzing how the Service is used.
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            Usage Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            refers to data collected automatically, either generated by the use
            of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">Website</div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            refers to the website of Vibe, accessible from{" "}
            <a href="www.vibesmart.pk">www.vibesmart.pk</a>
          </p>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            Customer or You
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            means the individual accessing or using the Service, or the company,
            or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            1. Collecting and Using Your Personal Data
          </div>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            Types of Data Collected:
          </div>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            1.1. Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            While using Vibe, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally, identifiable information may include, but
            is not limited to:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Delivery Addresses</li>
              <li>Usage Data</li>
            </ul>
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            1.2. Delivery Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            While using Vibe, we may request specific personally identifiable
            information essential for order processing and delivery. This may
            encompass:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Name</li>
              <li>Complete delivery address</li>
              <li>Phone number</li>
              <li>Order details</li>
              <li>Order ID</li>
            </ul>
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            1.3. Usage Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Automatically collected data during service use may include:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Device's Internet Protocol address</li>
              <li>Browser type and version</li>
              <li>Visited pages, time, and date</li>
              <li>Time spent on pages</li>
              <li>Unique device identifiers</li>
              <li>Other diagnostic data</li>
            </ul>
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            1.4. Payment Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Specific payment data essential for processing your transactions,
            this may entail:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Your payment method</li>
              <li>Encrypted, pseudonymized credit card information</li>
            </ul>
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            This information is used to initiate your payments and allocate them
            to the orders you've placed. Additionally, we utilize this data to
            securely store your payment information for future orders, subject
            to your consent.
          </p>
          <div className="inner_PrivacyPolicy_body_heading_div">
            2. Information Collected while Using the Application
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            During Vibe app usage, with user permission, the following
            information may be collected:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Location information</li>
              <li>
                Phone camera in order to capture screenshots for complains
              </li>
            </ul>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            This information is utilized to provide service features and enhance
            user experience, with data stored on VIBE’s servers or a Service
            Provider's server, or solely on the user's device. Users can manage
            data access through device settings.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            3. Tracking Technologies and Cookies
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Vibe employs cookies and similar tracking technologies to monitor
            activity and store information. These technologies include:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Browser Cookies</li>
            </ul>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Cookies may be "Persistent" or "Session" Cookies, serving various
            purposes such as functionality, policy acceptance, and tracking.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            4. Use of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE may use Personal Data for multiple purposes, including but not
            limited to:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>Service provision and maintenance</li>
              <li>Account management</li>
              <li>Contract performance</li>
              <li>Communication with users</li>
              <li>Providing news and offers</li>
              <li>Managing user requests</li>
              <li>Delivering targeted advertising</li>
              <li>Business transfers</li>
              <li>Data analysis and service improvement</li>
            </ul>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Personal information may be shared in specific situations, including
            with Service Providers, in business transactions, with affiliates,
            business partners, other users, or with user consent.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            5. Retention of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE retains Personal Data only as long as necessary for stated
            purposes, complying with legal obligations and resolving disputes.
            Usage Data may be retained for internal analysis, security
            enhancement, or legal compliance.
          </p>
          <div className="inner_PrivacyPolicy_body_heading_div">
            6. Transfer of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            User information, including Personal Data, may be processed and
            maintained at VIBE's operating offices or other locations, possibly
            outside the user's jurisdiction. By submitting information, users
            consent to such transfers, with VIBE ensuring data security and
            adherence to this Privacy Policy.
          </p>
          <div className="inner_PrivacyPolicy_body_heading_div">
            7. Disclosure of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE may disclose Personal Data in business transactions, for law
            enforcement or legal requirements, safeguarding rights, property, or
            safety, and protecting against legal liability.
          </p>
          <div className="inner_PrivacyPolicy_body_heading_div">
            8. Security of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            While VIBE employs commercially acceptable security measures,
            absolute security cannot be guaranteed for data transmission or
            storage over the Internet.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            9. Detailed Information on the Processing of Your Personal Data
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Service Providers may access Personal Data, processing it according
            to their Privacy Policies. Vibe may utilize third-party analytics
            services like Google Analytics, Firebase, Mixpanel, and Adjust, with
            opt-out options available.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Google Analytics, Firebase, Mixpanel, and Adjust have their
            respective privacy policies and data safeguarding measures.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            For more information on these services, users can refer to the
            privacy policies and terms provided by the respective service
            providers.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            10. Personal data of others
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            If you furnish us with personal data of other individuals via your
            utilization of our service (e.g., providing us with your friend's
            name, contact details, and residential address for a food/grocery
            delivery order on their behalf or providing sender's/recipient's
            delivery address and contact details, you affirm that you are
            authorized by these individuals to disclose their personal data to
            us and that they have consented to the collection, usage, and
            disclosure of their personal data by us for such purposes.)
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            11. Links to other websites
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            12. Children's Privacy
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Our Service does not address anyone under the age of 18. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 18. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 18 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            13. Right to amend
          </div>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            We retain the right to modify this privacy policy to ensure
            adherence to pertinent business, legal and statutory requirements.
            It is your responsibility to review the Privacy Policy regularly and
            you are strongly recommended to do so. You will be deemed to have
            agreed to the amended terms (whether or not reviewed by you) by your
            continued use of the mobile application following the date on which
            the amended terms are posted.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div"></p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
