import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";


const UnAuthRoutes = () => {


    return (
        <>
            <Routes>
                {/* <Route path='/' element={< />} /> */}
            </Routes>
        </>
    )
}

export default UnAuthRoutes