import React, { useState, useRef, useEffect } from 'react';
import Hide from '../../../assets/Path 2285.png';
import Show from '../../../assets/Path 2286.png';

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [content]);

  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
      <div className="accordion-title" onClick={onClick}>
        <h3>{title}</h3>
        <span>
          {isOpen ? (
            <img src={Hide} alt="hide" className="icon" />
          ) : (
            <img src={Show} alt="show" className="icon" />
          )}
        </span>
      </div>
      <div
        ref={contentRef}
        className="accordion-content"
        style={{ maxHeight: isOpen ? `${contentHeight}px` : '0px' }}
      >
        <div className="accordion-content-inner">{content}</div>
      </div>
    </div>
  );
};

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;