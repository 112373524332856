import React from 'react'
import Accordion from './Accordion'
function FAQs(){

    const accordionItems = [
        {
          title: 'How do I place an order with Vibe?',
          content: 'To order with Vibe, simply download our app and enter your location. Browse through a wide range of PSO’s convenience stores near you, explore various products, select your items, and add them to your cart. Proceed to checkout, make the payment, and your order will be ready for pick-up or home delivery!'
        },
        {
          title: 'Which convenience stores are open now near me?',
          content: 'Content for item 2',
        },
        {
          title: 'Does Vibe operate 24/7?',
          content: 'Content for item 3',
        },
        
        {
          title: 'Can I pay cash for Vibe orders?',
          content: 'Content for item 3',
        },
        
        {
            title: 'How can I pay for Vibe orders online?',
            content: 'Content for item 3',
          },
          
        {
            title: 'Can I order with Vibe for someone else?',
            content: 'Content for item 3',
          },
          
        {
            title: 'How much does Vibe charge for delivery?',
            content: 'Content for item 3',
          },
          
        {
            title: 'Does Vibe have a minimum order requirement?',
            content: 'Content for item 3',
          },
          
        {
            title: 'What is the difference between delivery and Pick-Up with Vibe?',
            content: 'Content for item 3',
          },

      ];
    

    return(
        <>
        <div className="main_FAQs_div">
        <div className='inner_FAQs_banner_div'>
                    <div className='inner_PrivacyPolicy_banner_text_div' id='FAQs_heading'>Got questions? We’ve got answers!</div>
                </div>

                <div className="inner_FAQs_body_text_div">

<div className="inner_FAQs_body_para_text_div">
                    <p className="inner_FAQs_body_heading_div">
                    Wondering about delivery times or payment options? Check out our FAQs for all the details.
                    </p>
                </div>

                <Accordion items={accordionItems} />
                </div>
        </div>
        </>
    )
}
export default FAQs