import React from "react";
import image from "../../../assets/Vibee1.png";
import image2 from "../../../assets/NoPath.png";
import image3 from "../../../assets/NoPath2.png";
import image4 from "../../../assets/NoPath4.png";
import image5 from "../../../assets/NoPath5.png";

import background from "../../../assets/background.png";
import rider from "../../../assets/rider.png";
import mob1 from "../../../assets/1.png";
import mob2 from "../../../assets/2.png";
import mob3 from "../../../assets/3.png";

import map from "../../../assets/map.png";
import V from "../../../assets/V.png";

import Group2 from "../../../assets/Group2.png";
import Group1 from "../../../assets/Group1.png";
import Group3 from "../../../assets/Group3.png";

import E from "../../../assets/E.png";
import group4 from "../../../assets/group11614.png";
import vibe_logo from "../../../assets/vibe-logo.png";

import google from "../../../assets/google_play.png";
import apple from "../../../assets/app_store.png";
import pack from "../../../assets/pack.png";
import shampoo from "../../../assets/shampoo.png";
import harpic from "../../../assets/harpic.png";
import powder from "../../../assets/powder.png";
import usb from "../../../assets/usb.png";
import perfume from "../../../assets/perfume.png";
import food from "../../../assets/food.png";
import polish from "../../../assets/polish.png";

import B from "../../../assets/B.png";

const MainPage = () => {
  return (
    <div className="main_div">
      <div className="image_container">
        <img src={image} alt="Vibe" className="c-VIBE-7" />
        <span className="Discover-convenience-at-your-fingertips-with-Vibe">
          Discover convenience at your fingertips with Vibe!
        </span>
      </div>
      <div className="Category_div">
        <span className="Categories">Categories</span>
      </div>

      <div className="CategoryContainer">
        <img src={V} alt="" className="V_image" />
        <div className="categories_container">
          <div className="category_item">
            <img src={image2} alt="NoPath" className="NoPath" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={image3} alt="NoPath" className="NoPath2" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={image4} alt="NoPath" className="NoPath---Copy" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={image5} alt="NoPath" className="NoPath3" />
            <div class="Snacks ">Snack</div>
          </div>

          <div className="category_item">
            <img src={shampoo} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={pack} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={harpic} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={powder} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>

          <div className="category_item">
            <img src={usb} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={perfume} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={food} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
          <div className="category_item">
            <img src={polish} alt="NoPath" className="NoPath6" />
            <div class="Snacks ">Snack</div>
          </div>
        </div>
      </div>
      <div className="c-VIBE-8">
        <div className="left">
          <div>
            <p className="Pick-up-Delivery">Pick-up & Delivery</p>
            <span className="Choose-your-convenience">
              Choose your convenience. Get your goodies on the go or delivered
              right to your doorstep, whatever suits your vibe.
            </span>
          </div>
        </div>
        <div className="right">
          <img src={rider} alt="rider" className="c-VIBE-9" />
        </div>
      </div>

      <div className="container">
        <span className="Interface">User-Friendly Interface</span>
        <div className="imageB">
          <img src={B}></img>
        </div>
        <div className="mob-container">
          <img src={mob1} alt="mob1" className="mob1" />
          <img src={mob2} alt="mob2" className="mob2" />
          <img src={mob3} alt="mob3" className="mob3" />
        </div>
      </div>

      <div className="With-a-few-taps-your-order-is-on-its-way-Enjoy-a-seamless-experience-from-browsing-to-checkout-designed-with-you-in-mind">
        <div className="child_class">
          With a few taps, your order is on its way. Enjoy a seamless experience
          from browsing to checkout, designed with you in mind.
        </div>
      </div>
      <div className="container1">
        <div className="background_image">
          <div className="map_section">
            <img src={map} alt="map" className="map_image" />
          </div>
          <div className="text_section">
            <div className="Discover-Vibe-in-your-city">
              <span>Discover Vibe</span>
              <span> in your city!</span>
            </div>
            <div className="Now-Serving-Karachi-Islamabad-and-Peshawar-Stay-tuned-Vibe-is-expanding-to-more-cities-soon">
              Now Serving
              <br></br>
              <span className="text-style-1"> Karachi</span>,
              <span className="text-style-2"> Islamabad</span>, and
              <span className="text-style-3"> Peshawar</span>!<br></br>
              Stay tuned! Vibe is expanding to <br></br> more cities soon.
            </div>
          </div>
        </div>
      </div>
      <div class="grid-container">
        <div class="item1"></div>

        <div class="item2">
          <div>
            <div className="logo"></div>
            <div className="contain">
              <div className="download">Download Application</div>

              <div className="item4">
                <div className="playstore"></div>
                <div className="appstore"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
