import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from '../../Header-Footer/Footer/Footer';
import Header from '../../Header-Footer/Header/Header';
import PrivacyPolicy from '../../pages/Auth-Pages/Privacy-Policy/Privacy-Policy';
import Terms_and_Conditions from '../../pages/Auth-Pages/Terms-and-Conditions/Terms-and-Conditions';
import MainPage from '../../pages/Auth-Pages/Main-Page/MainPage';
import FAQs from '../../pages/Auth-Pages/FAQs/FAQs';
import Partnerships from '../../pages/Auth-Pages/Partnerships/Partnerships';

const AuthRoutes = () => {

    return (
        <>
            <Header />
            <Routes>
                {/* <Route path='/' /> */}
                <Route path='/' element={<MainPage />} />
                <Route path='/Privacy-Policy' element={<PrivacyPolicy />} />
                <Route path='/Terms-&-Conditions' element={<Terms_and_Conditions />} />
                <Route path='/Partnerships' element={<Partnerships />} />
                <Route path='/FAQs' element={<FAQs />} />
                {/* <Route path='/' element={< />} /> */}
            </Routes>
            <Footer />
        </>
    )
}

export default AuthRoutes