import React, { useState } from 'react';
import vibelogo from '../../assets/vibe-logo.png'; // Adjust the path accordingly

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='main_header_div'>
      <div className='inner_header_logo_div'>
        <img src={vibelogo} alt="Logo" />
      </div>
      <div className='inner_menu_div'>
        <ul className={menuOpen ? 'open' : ''}>
          <li><a href="/">Home</a></li>
          <li><a href="/Privacy-Policy">Privacy Policy</a></li>
          <li><a href="/Terms-&-Conditions">Terms & Conditions</a></li>
          <li><a href="/Partnerships">Partnerships</a></li>
          <li><a href="/FAQs">FAQs</a></li>
        </ul>
      </div>
      <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </div>
  );
};

export default Header;
