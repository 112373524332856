import React, { useState } from 'react'
import CardGrid from './CardGrid'

import group11618 from '../../../assets/group11618.png';
import group11619 from '../../../assets/group11619.png';
import group11620 from '../../../assets/group11620.png';
import group11621 from '../../../assets/group11621.png';
import group11622 from '../../../assets/group11622.png';
import group11623 from '../../../assets/group11623.png';
import group11624 from '../../../assets/group11624.png';
import group11625 from '../../../assets/group11625.png';
import group11626 from '../../../assets/group11626.png';
import group11627 from '../../../assets/group11627.png';
import group11628 from '../../../assets/group11628.png';
import group11629 from '../../../assets/group11629.png';
import group11630 from '../../../assets/group11630.png';
import group11631 from '../../../assets/group11631.png';
import group11632 from '../../../assets/group11632.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function Partnerships() {


  const images = [
    group11618, group11619, group11620, group11621, group11622,
    group11623, group11624, group11625, group11626, group11627,
    group11628, group11629, group11630, group11631, group11632,
  ];

  const [Name, setName] = useState(null)
  const [Email, setEmail] = useState(null)
  const [BusinessEmail, setBusinessEmail] = useState(null)
  const [BusinessLocation, setBusinessLocation] = useState(null)
  const [Message, setMessage] = useState(null)

  const submit = () => {
    if (!Name || !Email || !BusinessEmail || !BusinessLocation || !Message) {
      toast.error("Please fill in all fields.");
    } else if (!/\S+@\S+\.\S+/.test(Email) || !/\S+@\S+\.\S+/.test(BusinessEmail)) {
      toast.error("Please enter valid email addresses.");
    } else {

      let body = {
        name: Name,
        email: Email,
        business_email: BusinessEmail,
        business_location: BusinessLocation,
        message: Message
      };

      axios.post('https://vibe.qbscocloud.net:8000/partnership/createRequest', body)
        .then(response => {
          if (response?.data?.status === true) {
            toast.success("Submission successful!");

            setName('')
            setEmail('')
            setBusinessEmail('')
            setBusinessLocation('')
            setMessage('')
          }
        })
        .catch(error => {
          toast.error("Submission failed. Please try again.");
          console.error(error);
        });

    }
  }






  return (
    <>
      <ToastContainer /> {/* // Place the ToastContainer outside of functions */}

      <div className="main_FAQs_div">
        <div className='inner_PrivacyPolicy_banner_div'>
          <div className='inner_PrivacyPolicy_banner_text_div'>Partners</div>
        </div>

        <div className="inner_FAQs_body_text_div">

          <div className="inner_FAQs_body_para_text_div">

            <p className="inner_Partnerships_body_heading_div">
              Partnerships </p>

            <p className='inner_FAQs_body_para_text_div'>
              Shoutout to our amazing partners! Together, we're bringing convenience to your fingertips,
              one order at a time.
            </p>

          </div>
          <div>
            <CardGrid images={images} />
          </div>

        </div>

      </div>

      <div className='main_partner_form_wrapper_div'>
        <div id="partner-form-wrapper">
          <div id="partner-form-container">
            <h2 className="partner-form-title">Become a Partner</h2>
            <p className="partner-form-subtitle">
              Interested in becoming a merchandising partner with Vibe? We'd love to hear from you!
            </p>
            <div className='form'>
              <span className="formlabels"> Name</span> <br />
              <input
                type="text"
                name="name"
                className="partner-form-input"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              /> <br />
              <span className="formlabels"> Email</span> <br />

              <input
                type="email"
                name="Email"
                className="partner-form-input"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="formlabels"> Business Email</span> <br />

              <input
                type="text"
                name="BusinessEmail"
                className="partner-form-input"
                value={BusinessEmail}
                onChange={(e) => setBusinessEmail(e.target.value)}
              />
              <span className="formlabels"> Business Location</span> <br />

              <input
                type="text"
                name='BusinessLocation'
                className="partner-form-input"
                value={BusinessLocation}
                onChange={(e) => setBusinessLocation(e.target.value)}
              />
              <span className="formlabels"> Message/Additional Information</span> <br />

              <textarea
                name="message"
                className="partner-form-textarea"
                rows="5"
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <div className='partner-form-button-div'>
                <button className="partner-form-button" onClick={submit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}

export default Partnerships