import React from 'react';

const CardGrid = ({ images }) => {
  return (
    <div className="card-grid">
      {images.map((image, index) => (
        <div className="card" key={index}>
          <img src={image} alt={`Card ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default CardGrid;
