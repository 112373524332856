import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <>
      <AppRoutes />

    </>
  );
}

export default App;

<ToastContainer />; // Place the ToastContainer outside of functions