import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoutes from './AuthRoutes/AuthRoutes';
import UnAuthRoutes from './UnAuthRoutes/UnAuthRoutes';


const AppRoutes = () => {


    const [authenticatedUser, setauthenticatedUser] = useState(true)

    return (
        <>
            <Router>
                {authenticatedUser ? (
                    <AuthRoutes />
                ) : (
                    <UnAuthRoutes />
                )}
            </Router>
        </>
    )
}

export default AppRoutes