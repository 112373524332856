import React from "react";

function Terms_and_Conditions() {
  return (
    <>
      <div className="main_PrivacyPolicy_div">
        <div className="inner_PrivacyPolicy_banner_div">
          <div className="inner_PrivacyPolicy_banner_text_div">
            Terms & Conditions
          </div>
        </div>

        <div className="inner_PrivacyPolicy_body_text_div">
          <p className="inner_PrivacyPolicy_body_para_text_div">
            This agreement explains how you can use the VIBE mobile application,
            created by Pakistan State Oil Company Limited (PSO). (The VIBE
            mobile application may also be referred to as "VIBE", "we", or "us"
            throughout this agreement). These terms apply to any new features
            that are added to the app in the future. In short, this agreement
            governs your use of VIBE's services.
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            Understanding these terms is important. By using the VIBE
            application, you confirm that you've read, understood, and accepted
            them. This also means you agree to how your information is handled,
            as explained in the VIBE’s Privacy Policy. If you do not agree with
            the terms, please do not access or use the mobile application, or
            the services.
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            The VIBE mobile application is accessible to individuals who are 18
            years of age or older. Users below the age of 18 must obtain consent
            from parent(s) or legal guardian(s), who by accepting these Terms
            shall agree to take responsibility for your actions and any charges
            associated with your use of the mobile application and/or purchase
            of goods. If you do not have consent from your parent(s) or legal
            guardian(s), you must stop using/accessing the application
            immediately.
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE reserves the right to conduct periodic background checks on you
            either using its own resources or using third-party services,
            without further reference or notice to you. These checks may include
            verification against government sanctions lists, civil and criminal
            court records, and negative media coverage. VIBE maintains the
            unilateral discretion to terminate your account and/or mobile
            application access based on the results of these background checks.
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE reserves the right to change or modify these terms (including
            any additional terms and conditions or policies, which are published
            on the website/application and incorporated into these terms) at any
            time. It is your responsibility to review the terms regularly and
            you are strongly recommended to do so. You will be deemed to have
            agreed to the amended terms (whether or not reviewed by you) by your
            continued use of the mobile application following the date on which
            the amended terms are posted.
          </p>

          <p className="inner_PrivacyPolicy_body_para_text_div">
            All photos and images of the goods shown on the application are also
            for illustrative purposes only and may differ from the actual goods.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            1.1 What is VIBE mobile application?
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE mobile application links you to selected convenience stores
            situated at PSO’s retail forecourts, for you to place order via the
            VIBE mobile application for a variety of goods including snacks,
            beverages, non-prepared food and miscellaneous non-food items
            (hereinafter collectively referred to as (“goods”) to be delivered
            to you and/or picked up by you at the selected convenience store.
            When you place an order for goods from the convenience store
            (“order”), VIBE acts as an agent on behalf of that convenience store
            operator to facilitate, process and conclude the order for you.
            Convenience store operators may be owned and operated by third party
            vendors, our retail forecourt dealers, or us. Where the convenience
            stores are operated by third party vendors, we are authorised by
            such vendors to accept payment. on their behalf.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            1.2 How to contact us
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            For customer support, you may reach out to us in multiple ways such
            as: through our in-app complain management system, via email at
            feedback@vibesmart.pk, or through
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            2 Use of the VIBE mobile application and VIBE Account
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.1 You will need to register yourself to use the VIBE mobile
            application. When you register for a VIBE account we will ask you to
            provide your personal information including your name, and your
            mobile phone number. To purchase an order via online payments you
            may need to provide us with your bank card/digital wallet details.
            Your unique One Time Password (OTP) should not be shared with anyone
            and you agree to keep it secret at all times. You are solely
            responsible for keeping your bank card/digital wallet details safe.
            VIBE shall not be liable for any misuse caused by disclosure of such
            information to the third party. You accept that all orders placed
            under your VIBE account are your sole responsibility.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.2 VIBE shall not be liable for orders that encounter
            pick-up/delivery issues due to incomplete, incorrect or missing
            information provided by you. You are obliged to provide information
            that is complete, accurate and truthful for the proper processing of
            the order, including your delivery address and contact information.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.3 Whilst VIBE is determined to provide services of best standards,
            it is accepted that VIBE shall not be liable for any loss, harm,
            damage or claim arising from the unauthorized access of the VIBE
            App, hacking or transmission or any virus which causes damage or
            loss to the VIBE customer
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.4 If you wish to delete your VIBE account, you can do so via the
            VIBE mobile application itself or by sending us an email requesting
            the same at feedback@vibesmart.pk. We may restrict, suspend or
            terminate your VIBE account and/or use of the mobile application, if
            we reasonably believe that:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.4.1 someone other than you are using your VIBE account;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.4.2 where you are suspected or discovered to have been involved in
            any activity or conduct that is in breach of these terms, our
            policies and guidelines, or involved in activity or conduct which we
            deem in our sole discretion to be an abuse of the mobile
            application; or
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            2.4.3 you are unable to satisfy the results of the background checks
            on an ongoing basis.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            3 Restrictions
          </div>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            3.1 Activities prohibited on the VIBE mobile application
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            The following is a non-exhaustive list of the types of conduct that
            are illegal or prohibited on the mobile application. VIBE reserves
            the right to investigate and take appropriate legal action against
            anyone who, in VIBE's sole discretion, engages in any of the
            prohibited activities. Prohibited activities include, but are not
            limited to the following:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.1 using the mobile application for any purpose in violation of
            local, state, or federal laws or regulations, including any false,
            deceptive, misleading, or fraudulent purpose;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.2 posting any content that infringes the intellectual property
            rights, privacy rights, publicity rights, trade secret rights, or
            any other rights of any party;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.3 posting content that is unlawful, obscene, defamatory,
            threatening, harassing, abusive, slanderous, hateful, or
            embarrassing to any other person or entity as determined by VIBE in
            its sole discretion or pursuant to local community standards;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.4 posting content that constitutes cyber-bullying, as determined
            by VIBE in its sole discretion;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.5 posting content that depicts any dangerous, life-threatening,
            or otherwise risky behavior;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.6 posting telephone numbers, street addresses, or last names of
            any person;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.7 posting URLs to external websites or any form of HTML or
            programming code;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.8 posting anything that may be “spam,” as determined by VIBE in
            its sole discretion;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.9 impersonating another person when posting content;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.10 harvesting or otherwise collecting information about others,
            including email addresses, without their consent;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.11 allowing any other person or entity to use your
            identification for posting or viewing comments;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.12 engaging in any other conduct that restricts or inhibits any
            other person from using or enjoying the mobile application, or
            which, in the sole discretion of VIBE, exposes VIBE or any of its
            customers, suppliers, or any other parties to any liability or
            detriment of any type; or
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.1.14 encouraging other people to engage in any prohibited
            activities as described herein.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            3.2 VIBE reserves the right but is not obligated to do any or all of
            the following:
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.2.1 investigate an allegation that any content posted on the
            mobile application does not conform to these terms and determine in
            its sole discretion to remove or request the removal of the content;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.2.2 remove content which is abusive, illegal, or disruptive, or
            that otherwise fails to conform with these terms;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.2.3 suspend or terminate a user’s access to the mobile application
            or their VIBE account upon any breach of these terms;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.2.4 monitor, edit, or disclose any content on the mobile
            application; and
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            3.2.5 edit or delete any content posted on the mobile application,
            regardless of whether such content violates these standards.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            4 Intellectual Property
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            All trademarks, logos, images, and service marks, including these
            terms as displayed on the mobile application or the VIBE website or
            in any of our marketing material, whether registered or
            unregistered, are the intellectual property of VIBE and/or third
            parties who have authorised us with the use (collectively the
            “trademarks”). You may not use, copy, reproduce, republish, upload,
            post, transmit, distribute, or modify these trademarks in any way
            without our prior express written consent. The use of the trademarks
            on any other website not approved by us is strictly prohibited. VIBE
            will aggressively enforce its intellectual property rights to the
            fullest extent of the law, including criminal prosecution. VIBE
            neither warrants nor represents that your use of materials displayed
            on the mobile application will not infringe rights of third parties
            not owned by or affiliated with VIBE. Use of any materials on the
            VIBE is at your own risk.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            5 Restrictions on Goods
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            5.1 Some of the goods we offer on VIBE mobile application are
            subject to restrictions for purchase (“Restricted Goods”), depending
            on the applicable laws of the country. These restrictions include
            minimum age requirements for Cigarettes/Tobacco products and any
            other goods that we reserve the right not to deliver to you based on
            the relevant statutory requirements of the time being in force. By
            placing an order for Restricted Goods, you confirm that you meet the
            legal requirements to purchase them.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            5.2 Cigarettes/Tobacco Products (“Tobacco”)
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            We may offer Tobacco on our VIBE mobile application where the laws
            allow. By offering Tobacco for sale on our mobile application, we do
            not purport to advertise, promote or encourage the purchase or use
            of Tobacco in any way. To purchase Tobacco, you must be of the
            statutory legal age. VIBE, the store operator and our delivery
            partner’s riders, as the case may be, reserve the right in their
            sole discretion:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            5.2.1 to ask for valid proof of age (e.g. ID card) to any persons
            before they deliver Tobacco;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            5.2.2 to refuse delivery if you are unable to prove you are of legal
            age; and/or
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            5.2.3 to refuse delivery to any persons for any reason whatsoever.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            5.4 Any offer for any Tobacco made on the Platform is void when it
            is prohibited by law.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">6 orders</div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.1 When you place an order on VIBE application, VIBE will confirm
            your order by sending you a confirmation via in-application
            notification containing the order receipt. Where applicable, orders
            will include delivery fees and any applicable tax (e.g. GST etc.).
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.4 Allergens – VIBE is not obligated to provide ingredient
            information or allergen information on the mobile application.
            Further, VIBE does not guarantee that the goods sold by store
            operator are free of allergens, unless otherwise required by
            applicable laws. If you have allergies, allergic reactions or
            dietary restrictions and requirements, please contact us at
            feedback@vibesmart.pk before placing an order.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.5 Please note that your order may be subject to additional terms
            and conditions provided by the store operator.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.6 Prior to placing the order for doorstep delivery:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.6.1 You are required to provide the delivery address in order for
            the mobile application to display the convenience stores available
            nearby your destination.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.6.2 Once you select a convenience store, you will be taken to that
            convenience store’s inventory page for you to select and add your
            goods to the cart.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.6.2 VIBE customer is required to provide and keep the address
            updated. In case the new address is not updated, and the order is
            dispatched to old address VIBE shall not liable for any loss and the
            refund shall not be processed.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            6.7 Placing the order
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            To complete an order, please follow the onscreen instructions after
            clicking ‘Confirm payment and address’. You may be required to
            provide additional details for us to complete your order. You are
            required to review and confirm that all the information you provide,
            including the amounts, delivery details, personal details, payment
            information, and voucher codes (if applicable) is true, accurate and
            complete before you place your order on the application. An order is
            successfully placed when you receive an in-application confirmation
            containing your order receipt from us.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            6.8 Cancelling an order
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Once the order has been placed and order number is generated, you
            can’t request for cancellation of the order.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            6.9 Refunds
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (i) Online payment orders
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            You have the right to a refund only if:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>
                Your order is partially fulfilled due to unavailability of
                product (You will receive notification of unavailable products)
              </li>
              <li>The goods received are not what had been ordered</li>
              <li>The goods received are not up to given standard</li>
            </ul>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            In case refund is to be given to you, we will issue refunds to your
            VIBE Wallet by default. Please note such refunds will be processed
            in accordance with our VIBE terms. You will have to submit your
            complain via the complaint management section present inside the
            VIBE application.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            We may request additional information or supporting documents,
            including picture proof of physical receipts for
            pick-p/cash-on-delivery orders, before processing any refund.
            Failure to provide such information may result in the refusal of
            refund processing, as per our discretion.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            In case a refund has not been received, users are advised to check
            their VIBE account and contact the VIBE helpline, as refunds may
            take some time to be processed. If further assistance is needed,
            users are encouraged to reach out to our customer support team at{" "}
            <a href="#">feedback@vibesmart.pk.</a>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Additionally, VIBE reserves the right to cancel any order and/or
            suspend, deactivate, or terminate a user's VIBE account if
            fraudulent behavior or activity is reasonably suspected or detected.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            6.9.1 VIBE wallet:
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            The VIBE wallet will be issued to a user immediately upon successful
            registration on the VIBE mobile application. The monetary value in
            the VIBE wallet will only be credited by us owing to refunds that
            might arise due to:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            a) Unavailability of goods whose payment was already made by online
            payment options;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            b) Refunds for not up to standard goods that were handed over to
            customer. However, it is customer’s responsibility to check the
            expiry date of the products before accepting them. In case, a claim
            is lodged after accepting the expired good by customer, VIBE will
            have to carry out due diligence before issuing the refunds.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            The balance in the VIBE wallet can only be used to purchase goods
            from the convenience stores listed on the VIBE mobile application.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            If you choose to utilise the balance in your VIBE wallet for an
            order and the value of the order exceeds the balance in your VIBE
            User Account, the balance in your VIBE wallet shall be utilised in
            full and the remaining amount of the order shall be charged to the
            online payment method or cash-on-delivery/pick-up.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            If you choose to utilise the balance in your VIBE wallet for an
            order and the value of the order is less than the balance in your
            VIBE wallet, VIBE will only deduct the value of the order from the
            balance in the VIBE wallet. Any remaining balance in your VIBE
            wallet may be used on subsequent orders.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE wallet refunds cannot be directly transferred to your original
            payment source (e.g., credit card, debit card, digital wallet etc.).
            Refunded amounts will be credited to your VIBE wallet balance for
            future use within the app.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.2 The balance in the VIBE Wallet cannot be withdrawn as cash.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.3 The VIBE Wallet is not transferable.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.4 VIBE does not pay interest on the outstanding balance in the
            VIBE User Account.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.5 Each User is allowed to operate only one (1) VIBE User
            Account. Any suspected breach of this condition may lead to the
            suspension of the User’s access to all VIBE User Accounts of the
            User.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.6 VIBE may, at times, request additional information from you
            regarding your identity and your use of the VIBE User Account,
            including transactions made, identification information, and the
            number of VIBE User Accounts held by you. You agree to provide
            accurate, complete, and truthful responses to such queries from VIBE
            in good faith. Failure to do so may result in the immediate
            suspension or termination of your VIBE User Account and/or VIBE
            Account.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.7 VIBE reserves the right to suspend or discontinue the VIBE
            User Account at any time, for any reason, including, but not limited
            to the following:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.8 For any suspected violation of applicable laws, rules,
            regulations, orders, directions, notifications, and guidelines,
            including notices and guidelines issued by the State Bank of
            Pakistan from time to time, or for any violation of any instructions
            or notifications issued by VIBE to the User from time to time, or
            any terms of usage of VIBE’s services, including these VIBE User
            Account Terms;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.9 Any suspected discrepancy, false information, and/or
            inaccuracies in the User’s Details and any other identification,
            financial information, or supporting documents provided by the User;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.10 To combat potential fraud, sabotage, money laundering,
            terrorism financing, willful destruction, or for any other force
            majeure event; or
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            6.9.11 VIBE, in its sole opinion and discretion, believes that
            cessation/suspension of the VIBE User Account is necessary for any
            other legitimate purpose.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            7 Prices and Payments
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.1 Prices displayed on the VIBE are in Pakistani Rupees (“PKR”) and
            are subject to applicable taxes. Users acknowledge that prices and
            offers on the VIBE mobile application may vary from those offered by
            our Shop operators elsewhere, including their own websites, mobile
            applications, or brick-and-mortar outlets.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.2 The manner in which we display the prices of goods on the VIBE
            may vary depending on the store operator. Subject to applicable
            laws, the prices reflected on VIBE:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.3 Delivery fees apply to every order on VIBE unless:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.3.1 you choose to collect your order directly from the shop
            (“Pick-Up”);
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.3.2 you possess a valid promotional or discount voucher and apply
            it at checkout.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.4 Prices and additional charges (including but not limited to
            delivery, small order or service fees) indicated on the VIBE are as
            of the time of each order and may be subject to change.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.5 A breakdown of the prices and additional charges (including but
            not limited to delivery, small order, service fees etc.) are
            displayed before checkout. By placing an order on VIBE, you agree to
            all amounts, additional charges, and the final ‘Total’ amount
            displayed to you.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.6 You can select any of the different payment methods offered on
            the VIBE Platform, including:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.6.1 Online payment channels.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.6.2 Cash-on-Delivery/Cash-on-Pickup.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.6.3 Such other payment methods we offer vary from time to time.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.7 If you possess existing credit in your VIBE Wallet or valid
            promotional or discount vouchers, you can use these to pay for part
            or all of your order, as applicable.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.8 Upon successful placement of an order on VIBE, you will receive
            an in-app notification with your order receipt. Delivery fees will
            not appear in your order receipt if you opt for pick-up.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.9 Payment Methods
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE reserves the right to offer additional payment methods and/or
            remove existing payment methods at any time at its sole discretion.
            If you opt to pay using an online payment method, the payment will
            be processed by our third-party payment service provider(s). With
            your consent, your online payment information will be stored with
            our third-party payment service provider(s) for future orders. VIBE
            does not retain your payment information.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.10 You are responsible for ensuring that your selected online
            payment mode of payment has sufficient funds to cover the payment
            for an order. VIBE assumes responsibility for payments made on our
            mobile application, including refunds, chargebacks, cancellations,
            and dispute resolution, provided they are reasonable and justifiable
            and in accordance with these terms.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            7.11 Disputed transaction should be immediately notified and claimed
            by the Customer via Complaint Form available at VIBE mobile
            application. Customer must lodge a dispute within 24-48 hours of
            conducting transaction. VIBE reserves the right not to entertain the
            complaints if reported after the expiry of aforementioned time.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            8 Delivery, Pick-Up and Vendor Delivery
          </div>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.1 Delivery Areas
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            You realize that the store operators associated with VIBE offer
            their goods within designated delivery areas, and the availability
            of convenience stores can vary based on your delivery address. When
            you input your delivery address on VIBE, you will be presented with
            the stores available to you at that moment. Please note that
            delivery areas may be subject to expansion, reduction, or
            modification due to weather conditions, traffic conditions, and
            force majeure situations.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.2 Delivery Time
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE shall deliver your order to the delivery address provided by
            you. You may choose for your order to be delivered “ASAP” or
            scheduled for a specific time. An estimated delivery time will be
            provided to you via your in-app notification, but delivery times
            shall vary depending on factors that are not controlled by us (e.g.
            order quantity, distance, time of day (peak periods), weather
            conditions, traffic conditions, etc.). You acknowledge that the
            delivery time we provide is only an estimate and orders may arrive
            earlier or later. To ensure that you do not miss a delivery of an
            order, you should ensure that either you or someone is at the
            delivery location to receive the order once an order is placed. If
            your order contains Tobacco (if applicable) and you or the recipient
            is or appears to be below the legal age, or fails to provide a valid
            proof of ID, reserves the right not to deliver your order to you.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.3 Unsuccessful or Failed Deliveries
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            8.3.1 Without affecting any other provisions in these terms, in
            instances where we attempt to deliver an order but encounter
            obstacles caused by you, including but not limited to:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (i) absence of anyone to receive the order;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (ii) inability to reach you despite efforts to contact you using the
            provided phone number;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (iii) inadequate or restricted access hindering successful delivery;
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (iv) absence of a suitable or secure location for order placement;
            or
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            (v) for restricted goods, failure to meet age requirements or
            delivery deemed unsafe or inappropriate,
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            we will communicate with you through various means, such as phone
            calls, messages, or emails, to notify you of the unsuccessful
            delivery and discuss next steps. In such situations, VIBE reserves
            the right to cancel the order without issuing a refund or other
            remedy.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.3.2 No-show Cancellations
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            If you are unreachable or fail to receive the order within ten (10)
            minutes after it arrives at your delivery address, VIBE reserves the
            right to cancel the order without issuing a refund or other remedy.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.4 Wrong order, Missing Items, Defective Goods
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Upon receiving your order, if you encounter any issues such as
            receiving the wrong order, a defective order, or missing items,
            please promptly reach out to customer support using one of the
            methods specified in clause 1.2 above. In certain instances, VIBE
            may request photographic evidence and/or additional information to
            thoroughly investigate the problem with your order. If we determine
            that the order or goods you received do not meet satisfactory
            standards in terms of condition or quality, we will compensate you
            for the affected order or parts thereof.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            8.5 Pick-Up
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            8.5.1 You have the option to personally collect your order from
            VIBE's convenience stores instead of opting for delivery. VIBE
            commits to hold your order at its premises for up to sixty (60)
            minutes from your order placement time. After the holding time, VIBE
            is not obligated to provide the order if you fail to collect it
            within this timeframe.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            8.5.2 If there are unreasonable delays in collecting your order due
            to your actions, you accept the risk of any damage, loss,
            deterioration in quality, or changes in the condition of the goods
            (e.g., temperature changes affecting their suitability for
            consumption). In such instances, you are not eligible for a
            replacement or refund of the goods. It is your responsibility to
            inspect the goods/order upon collection and promptly report any
            issues or defects to the store operator before leaving VIBE's
            premises.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            9 Vouchers, Discounts and Promotions
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.1 Periodically, VIBE may introduce marketing and promotional
            campaigns featuring voucher codes, discounts, and other promotional
            offers for use on the mobile application. These vouchers are subject
            to specific validity periods, redemption periods, usage
            restrictions, limits, and availability terms.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.2 Vouchers may not be combinable with other promotions, discounts,
            or vouchers unless explicitly stated otherwise. Further terms and
            conditions, including vendor-specific terms, may also apply to the
            use of vouchers.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.3 Unless stated otherwise, vouchers are exclusively valid for use
            on the VIBE mobile application.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.4 Vouchers are non-transferable, non-exchangeable, non-refundable
            and cannot be exchanged for cash.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.5 VIBE reserves the right to modify, revoke, or change the terms
            and conditions of vouchers without prior notice. Additionally, VIBE
            may void, terminate, or reject the use of any voucher without prior
            notification.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            9.6 VIBE may, at its discretion, cancel, exclude, disqualify, or
            prohibit you or specific vendors from using vouchers without prior
            notice.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            10 Representations, Warranties and Limitation of Liabilities
          </div>
          <div className="inner_PrivacyPolicy_body_subheading_div">
            10.1 Representations and Warranties
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            When it comes to VIBE, we want to be transparent about how it works.
            You understand and agree that the content, goods, products, or
            services provided on the mobile application are available "as is"
            and "as available." Your use of the mobile application and reliance
            on any content or services accessed through it are entirely at your
            own discretion and risk.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Please note that any photos or images of products shown on the VIBE
            are for illustrative purposes only. The actual goods may differ from
            what is displayed.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            While we strive to ensure the availability and quality of our
            services, we cannot guarantee that the mobile application will
            always be secure, timely, uninterrupted, error-free, or free from
            technical issues, defects, or viruses. You should anticipate
            occasional interruptions due to scheduled maintenance, internet or
            electronic communication issues, or unforeseen events beyond our
            control.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            10.2 Limitation of Liability
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Subject to legal allowances, VIBE (including its employees,
            directors, agents, representatives, affiliates, and parent company
            i.e. PSO) disclaims all liability (whether arising from contract,
            negligence, or otherwise) for any loss or damage incurred by you or
            any third party in connection with our application, services, linked
            websites, or any content posted on them. Your sole recourse
            concerning the application is to cease using it. VIBE, its entities,
            agents, representatives, and service providers are not liable for
            indirect, special, incidental, consequential, or exemplary damages
            arising from your use of application or any related claims. These
            exclusions encompass damages like lost profits, data, goodwill, work
            stoppage, computer failures, or other commercial losses, even if
            VIBE or its entities were advised of such possibilities. However, in
            jurisdictions where such exclusions are not permitted, VIBE's
            liability is limited as per legal regulations.
          </p>

          <div className="inner_PrivacyPolicy_body_subheading_div">
            10.3 Store operators’ representations
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE shall neither be liable for actions or omissions of the store
            operator nor you in regard to provision of the goods and where
            delivery applies to your order. VIBE does not assume any liability
            for the quantity, quality, condition or other representations of the
            goods and/or services provided by store operator or guarantee the
            accuracy or completeness of the information displayed on the store
            operators’ listing/offering on the application. Nothing in these
            terms shall exclude store operator’s or delivery partner’s liability
            for death or personal injury arising from gross negligence or
            willful misconduct.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            11 Store operator’s Liability
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            Store operators are solely responsible for the preparation,
            condition and quality of goods sold to you. In cases of home
            delivery, delivery partner is responsible for delivery of the foods
            and/or orders. VIBE shall not be responsible or liable for any loss,
            damage, expense, cost or fees arising from your contractual
            relationship with the store operator.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            12 Personal Data (Personal Information) Protection
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            By using our services, you consent to VIBE and its affiliate
            companies collecting, processing, and disclosing your Personal Data
            as outlined in these Terms and detailed in our Privacy Policy. Our
            Privacy Policy is an integral part of these Terms and can be
            accessed through the VIBE mobile application and its webpage.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            13 Indemnity
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            The indemnifying party agrees and undertakes that, except in cases
            of gross negligence or wilful misconduct, each party shall defend,
            indemnify and hold harmless the other party’s indemnified party from
            and against all fines, penalties, liabilities, claims, demands,
            losses, damages, suits, cause of action, judgments, awards, expenses
            and costs (including reasonable attorneys’ fee and costs) of any
            kind and character resulting from or caused by the actions and
            omissions of the indemnifying party which shall include but not be
            limited any breach by the other party of its respective obligations
            hereunder or shall be incidental to any death, bodily injury, or
            tangible property damage proximately caused by the indemnifying
            party's gross negligence or wilful misconduct and/or for any
            representations made by either party which are proved incorrect or
            untrue when made including its legal status presented hereunder
            during the performance of the agreement. Gross negligence or willful
            misconduct means any act or failure to act (whether sole, joint or
            concurrent) by a person that was intended to cause or was in
            reckless disregard of, or wanton indifference to, the harmful
            consequences to the safety or property of another person which the
            person acting or failing to act knew, or should have known, would
            result from such act of omission, provided that gross negligence or
            willful misconduct does not include any act or failure to act
            insofar as it: constituted mere ordinary omission or oversight.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            14 Third Party Links and Websites
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            When using the application, you may encounter links to third-party
            products, websites, services, and offers. Clicking on these links is
            done at your own risk, as they are not owned or controlled by VIBE.
            We do not endorse or have control over these third-party websites,
            their content, security, services, privacy policies, or practices.
            Therefore, we are not responsible for any loss or damage resulting
            from your access or use of these third-party links or websites.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            15 Termination
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE reserves the right to terminate, suspend, or delete your
            account and access to the application, including any services
            provided to you, for various reasons, including if:
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            <ul>
              <li>
                Your use of the application is deemed unacceptable by VIBE.
              </li>
              <li>
                Background checks conducted continuously are unsatisfactory.
              </li>
              <li>
                You are suspected or found to be engaged in activities breaching
                our Terms.
              </li>
            </ul>
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            While VIBE may provide a warning before termination, it is not
            obligated to do so.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            16 Amendments
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            VIBE reserves the right to make changes to these terms at any time
            at its sole discretion. The updated terms will be effective
            immediately upon posting, as per applicable laws. It's your
            responsibility to review the terms regularly. If you disagree with
            any part of the updated terms, you must cease using the application
            immediately.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            17 Severability
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            If any court of competent jurisdiction finds any provision of these
            terms invalid, the remaining provisions will still be valid and
            enforceable. The invalidity of one provision does not affect the
            validity of others. No waiver of any provision in these Terms will
            be considered a permanent waiver of that provision or any other
            provision.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            18 Governing Law
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            These terms and any dispute or claim arising out of or in connection
            with it or its subject matter or formation (including
            non-contractual disputes or claims) shall be governed by and
            construed in accordance with the laws of Pakistan and the courts of
            Karachi shall have exclusive jurisdiction.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            19 Prevailing Language
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            In the event of a dispute as to the terms, the English version shall
            prevail. The English language version of these terms shall control
            in all respects and shall prevail in case of any inconsistencies
            with translated versions.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            20 Convenience Stores/ VIBE Stores
          </div>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            20.1 Due to delivery limitations, VIBE may need to set a maximum
            order amount or restrict the quantities of products from stores in
            its sole discretion.
          </p>
          <p className="inner_PrivacyPolicy_body_para_text_div">
            20.2 We have the right, though not the obligation, to limit or
            restrict the sale of our products to certain individuals, regions,
            or jurisdictions. Any offer for our products on the mobile
            application is void where prohibited by law.
          </p>

          <div className="inner_PrivacyPolicy_body_heading_div">
            21 Delivery partner’s tip is sole discretion of the customer, and
            VIBE has no stake in it. Hence, once the order has been delivered by
            the rider, tips cannot be refunded through VIBE.
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms_and_Conditions;
