import React from 'react'
import vibelogo from "../../assets/vibe-logo.png";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";

function Footer() {
    return (
        <>
            <div className='main_footer_div'>
                <div className='footer_top_vibelogo_div'>
                    <img src={vibelogo} alt="" />
                </div>
                <div className='inner_footer_div'>
                    <div className='inner_footer_div1'>
                        <div style={{ fontWeight: "600" }}>
                            Let's Talk
                        </div>
                        <div>
                            Have questions or feedback?
                            <br />
                            Shoot us an email at
                        </div>
                        <div>
                            feedback@vibesmart.pk
                        </div>
                    </div>

                    <div className='inner_footer_div2'>
                        <div><a href="/Privacy-Policy">Privacy Policy</a></div>
                        <div><a href="/Terms-&-Conditions">Terms & Conditions</a></div>
                        <div><a href="/Partnerships">Partnerships</a></div>
                    </div>

                    <div className='inner_footer_div3'>
                        <div>Stay Connected</div>
                        <div>
                            Follow us on
                            <br />
                            Instagram, Facebook, and X
                            <br />
                            for the latest updates and mouth-watering deals.
                        </div>
                        <div className='inner_footer_div3_logo_holder'>
                            <img src={instagram} alt="" />
                            <img src={facebook} alt="" />
                            <img src={twitter} alt="" />
                        </div>
                    </div>
                </div>
                <div className='inner_end_footer_text_div'>© 2024 Vibe. All rights reserved</div>
            </div>
        </>
    )
}

export default Footer
